.MeetTheTeam {
  min-height: 1000px;
  position: relative;
}

#team {
  margin-top: 80px;
}

.adjust-team-1 {
  position: absolute;
  top: 100px;
  left: 23vw;
}
.MeetTheTeam {
  margin-top: -60px !important;
}
.adjust-team-2 {
  position: absolute;
  left: 100px;
}
@media (max-width: 1305px) and (min-width: 1101px) {
  /* .team-scroll-to {
    position: absolute;
    top: 700px !important;
    right: 200px !important;
  } */
  .team-scroll-to {
    left: 60px !important;
  }
  .MeetTheTeam {
    flex-direction: row !important;
    min-height: 700px;
  }
  .adjust-team-1 {
    left: 300px !important;
    top: 100px;
  }
  .adjust-team-2 {
    left: 100px;
  }
}
@media (max-width: 500px) {
  #team {
    margin-top: 0;
  }
}

@media (max-width: 1100px) {
  .team-scroll-to {
    display: none;
  }
}

@media (max-width: 1100px) {
  .MeetTheTeam {
    min-height: fit-content;
    position: relative;
    flex-direction: column-reverse;
    margin-top: -50px;
  }

  .adjust-team-1 {
    position: relative !important;
    top: 0;
    left: 0;
    margin: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 100vw;
  }

  .adjust-team-2 {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;
    left: 0;
    width: 100vw;
  }
}

.team-scroll-to {
  position: absolute;
  bottom: 15vh !important;
  left: 100px;
  z-index: 100;
}
