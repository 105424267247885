@import url("https://db.onlinewebfonts.com/c/82023538f68f7bb9cc6cd054af2a0aa8?family=Riffic");
.App {
  text-align: center;
  overflow-x: hidden !important;
  z-index: 1;
  background-image: url("./assets/images/BackGround Images/BackgroundElements.png");
  animation: slide 100s linear infinite;
  /* transition: scroll 1s ease-in-out; */
  max-width: 2000px !important;
  margin: auto;
  box-sizing: border-box;
}
/* @media (max-width: 1140px) and (min-width: 1100px) {
  h1 {
    font-size: 27px !important;
  }
} */

.navbar-container {
  position: fixed;
  height: 40px;
  width: 100vw;
  top: 0;
  left: 0;
  box-shadow: 0 0 rgba(115, 114, 114, 0.503);
  z-index: 100;
}
@media (max-width: 1550px) and (min-width: 1000px) {
  .controll-width {
    max-width: 100vw !important;
  }
}

.controll-width {
  max-width: 80vw;
  margin: auto;
}

@media (max-width: 800px) and (min-width: 460px) {
  .controll-width {
    max-width: 100vw;
    margin: auto;
    padding: 50px;
  }
}

@media (max-width: 1000px) {
  .controll-width {
    max-width: 100vw;
    margin: auto;
    padding: 0;
  }
}

.scroll-to-top-button {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 15px;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(211, 220, 220);
  opacity: 0.75;
  cursor: pointer;
  transition: all 0.3s ease-out;
  color: white;
}

.scroll-to-top-button:hover {
  opacity: 1;
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -400vh;
  }
}

* {
  margin: 0;
  padding: 0;
}

.content {
  margin-top: 79px;
}

.slim-description {
  min-width: 250px;
  max-width: 300px;
}

.heading {
  font-size: 80px !important;
  font-weight: 800;
  font-family: "Riffic", sans-serif !important;
}
.heading-with-background {
  width: 100vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 300px;
}
.heading-with-background h1 {
  font-size: 120px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 800;
  white-space: nowrap;
  opacity: 0.1;
}

.heading-with-background h1,
.heading-with-background h2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@font-face {
  font-family: "Riffic";
  src: url("./assets/Font/Riffic\(heading\)/RifficFree-Bold.ttf")
    format("truetype");
}

.mini-heading {
  font-size: 30px !important;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

@media (max-width: 1100px) and (min-width: 701px) {
  .heading-with-background h1 {
    font-size: 80px !important;
  }
}
@media (max-width: 1000px) {
  .heading {
    font-size: 30px !important;
  }
}
@media (max-width: 700px) and (min-width: 501px) {
  .mini-heading {
    font-size: 25px !important;
  }
  .content {
    margin-top: 50px;
  }
  .heading-with-background h1 {
    font-size: 60px !important;
  }
}

.center-heading {
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 500px) {
  .heading {
    font-size: 30px !important;
  }
  .mini-heading {
    font-size: 25px !important;
  }
  .content {
    margin-top: 50px;
  }
  .heading-with-background h1 {
    font-size: 40px !important;
    margin: 0;
    padding: 0;
  }
  .heading-with-background h2 {
    font-size: small !important;
  }
}
.pop {
  position: fixed;
  box-shadow: none;
  top: 0px;
  right: 0;
  z-index: 1000;
}
.text-left {
  text-align: left;
}
.margin-auto {
  margin: auto;
}
.shadow {
  text-shadow: 2px 2px 2px 2px black;
  color: white !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  /* color: rgb(66, 65, 65) !important; */
}

.capitalize {
  text-transform: capitalize;
}

.give-top-space {
  padding-top: 50px;
}

.give-more-top-space {
  padding-top: 150px;
}

.give-bottom-space {
  padding-bottom: 50px;
}

.give-more-bottom-space {
  padding-bottom: 150px;
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 600px) {
  h3 {
    font-size: 19px !important;
  }
  h1 {
    font-size: 25px !important;
  }
}

p {
  font-size: 18px;
  font-weight: 600;
  color: rgba(51, 50, 50, 0.83);
  line-height: 30px;
}

.light-weight {
  font-weight: lighter !important;
}

.green {
  color: #8faf3d;
}
.light-green {
  color: #7ac054;
}
.yellowgreen {
  color: yellowgreen;
}
.red {
  color: #fd2955;
}

.dark-red {
  color: #84071d;
}

.dark {
  color: #1d2434 !important;
}
.blue {
  color: #3e92c6;
}

.yellow {
  color: #fee67d;
}

.sky-blue {
  color: #3ebfc6;
}

.dark-blue {
  color: #3e55c6;
}

.orange {
  color: #fa6a18;
}
.light-orange {
  color: #ce5619b6;
}
.faded-orange {
  color: #eca67d;
}
.faded-pink {
  color: #ffd4dd;
}
.pink {
  color: #e36df9;
}
.violet {
  color: #7265d9;
}

.violet-fade {
  color: #7354a4;
}
.purple {
  color: #a356ff;
}

.zero {
  margin: 0 !important;
  padding: 0 !important;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 500;
  color: #909294;
}

label {
  color: #3f4242;
  font-weight: 500;
}

.bg-sandal {
  background-color: #d4ba8b;
}

.bg-blue {
  background-color: #3e86c6;
}

.bg-white {
  background-color: white;
}

.bg-pink {
  background-color: #ff52f9;
}

.bg-violet {
  background-color: #7265d9;
}

.bg-pale-violet {
  background-color: #ff52f9;
}

.bg-faded-dark {
  background-color: #1d2434;
}

.bg-faded-light-dark {
  background-color: #354052;
}

.bg-orange {
  background-color: #cc5417;
}

.bg-faded-orange {
  background-color: #fa6a18;
}

.bg-yellow {
  background-color: #ffc16a;
}

.bg-pale-yellow {
  background-color: #ffe5b0;
}

.bg-red {
  background-color: #fd2655d0;
}

.bg-green {
  background-color: #78b16c;
}

.bg-pale-sandal {
  background-color: #fdffec;
}

.bullet {
  width: 20px;
  height: 20px;
  margin: auto;
}

.white {
  color: white;
}

.faded-black {
  color: #1d2434;
}

/* icons */
.fa {
  padding: 10px;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 5px 5px;
  border-radius: 50%;
}

@media (max-width: 700px) {
  .fa {
    font-size: 15px;
  }
}

/* .fa:hover {
  opacity: 0.7;
} */

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #125688;
  background: linear-gradient(#b33a7d, #ee8f91);
  color: white;
}

.fa-pinterest {
  background: #cb2027;
  color: white;
}

.fa-snapchat-ghost {
  background: #fffc00;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.fa-skype {
  background: #00aff0;
  color: white;
}

.fa-android {
  background: #a4c639;
  color: white;
}

.fa-dribbble {
  background: #ea4c89;
  color: white;
}

.fa-vimeo {
  background: #45bbff;
  color: white;
}

.fa-tumblr {
  background: #2c4762;
  color: white;
}

.fa-vine {
  background: #00b489;
  color: white;
}

.fa-foursquare {
  background: #45bbff;
  color: white;
}

.fa-stumbleupon {
  background: #eb4924;
  color: white;
}

.fa-flickr {
  background: #f40083;
  color: white;
}

.fa-yahoo {
  background: #430297;
  color: white;
}

.fa-soundcloud {
  background: #ff5500;
  color: white;
}

.fa-reddit {
  background: #ff5700;
  color: white;
}

.fa-rss {
  background: #ff6600;
  color: white;
}

/* font sizes */
.font-size-small {
  font-size: small;
}

@media (max-width: 1000px) {
  .responsive-small {
    font-size: x-small !important;
  }
}

.hide-scroll-bar {
  display: block;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none !important;
}
