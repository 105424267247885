.input {
  height: 100px;
  border: 2px solid;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  background-color: transparent;
  font-size: 30px;
  margin-left: 10px;
}

@media (max-width: 600px) {
  .input {
    width: 90vw !important;
  }
}

.input::placeholder {
  font-size: 30px;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  margin-left: 10px;
}

.label h1 {
  margin: 0;
}

.TextBox {
  width: fit-content;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.textbox-label {
  font-size: 30px !important;
  text-align: left;
  margin: 2px 5px;
  max-width: 80vw;
}

@media (max-width: 600px) {
  .textbox-label {
    font-size: 20px !important;
  }
}
