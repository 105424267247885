@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");
body {
  margin: 0;
  font-family: "Product Sans", sans-serif !important;
  /* font-family: "arial", sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden !important; */
  /* max-width: 100vw !important; */
  max-width: 100%;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
  max-width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/Font/Product-Sans\(paragraphs\)/Product Sans Regular.ttf")
    format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
