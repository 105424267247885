.navbar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  background-color: white;
  box-shadow: 0 0 17px 0 rgb(199, 198, 198);
  border-radius: 2px;
  z-index: 100;
  transform-style: preserve-3d;
  display: flex;
  align-items: flex-end;
  justify-content: right;
}

.font-button {
  outline: none;
  border: none;
  background-color: black !important;
  color: white !important;
  padding: 2px 10px !important;
  border-radius: 3px !important;
  position: absolute;
  right: 100px;
  top: 5px;
  box-shadow: none !important;
}

.font-button-default {
  outline: none;
  border: none;
  background-color: black;
  color: white;
  padding: 2px 10px;
  border-radius: 3px;
  position: absolute;
  right: 50px;
  top: 5px;
}

.updown {
  width: 15px;
  height: 15px;
  margin-right: -5px;
  /* background-color: white; */
}
