.HamburgerIcon {
  padding: 7px;
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 65px;
  position: relative;
  transition: all 1s ease-out;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 black;
  background-color: white;
  transition: all 0.3s ease-out;
}

.HamburgerIcon:hover {
  box-shadow: 0 0 5px 0 black;
  transform: translate3d(0px, 10px, -10px);
}

.HamburgerIcon div {
  width: 39px;
  height: 5px;
  background-color: black;
  margin: 3.5px;
}

.HamburgerIcon :nth-child(odd) {
  width: 25px;
}

.HamburgerIcon .last {
  margin-top: 5px;
  margin-left: 18px;
}

@media (max-width: 600px) {
  .HamburgerIcon {
    width: 60px;
    height: 50px;
  }
  .HamburgerIcon div {
    width: 37px;
    height: 4px;
    margin: 3px;
  }
  .HamburgerIcon :nth-child(odd) {
    width: 20px;
  }

  .HamburgerIcon .last {
    margin-left: 20px;
  }
}
