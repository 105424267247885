.skewContainer::-webkit-scrollbar {
  display: none !important;
}

.skewContainer {
  behavior: smooth;
  scroll-behavior: smooth;
  overflow-y: scroll !important;
  overflow-x: hidden;
  background-image: url("../../../../assets/images/BackGround Images/BackgroundElements.png");
  object-fit: cover;
  animation: slide 100s linear infinite;
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -400vh;
  }
}

.scrollContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  /* overflow: hidden; */
}
