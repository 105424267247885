.inner-pop-button {
  width: 30px;
  height: 30px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  margin: 5px !important;
  box-shadow: none !important;
}

.my-toggle-button {
  display: flex !important;
  align-items: center !important;
  justify-content: right !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}
