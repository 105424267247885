.bg-boy {
  display: block;
  background-image: url("../../assets/images/Chidren/16-2.png");
  background-position: center center;
  background-size: 100% 100%;
  min-width: 100vw;
  min-height: 500px;
  padding: 70px 0;
}

.bg-boy-image {
  width: 100%;
  height: 300px;
}
.mobile-version {
  display: none;
}
.whatwedo-scroll-to {
  position: absolute;
  bottom: 147px;
  left: 100px;
  z-index: 100;
}

@media (max-width: 1550px) and (min-width: 1000px) {
  .whatwedo-scroll-to {
    bottom: 100px;
    left: 50px;
  }
  .bg-boy {
    height: 200px !important;
    min-height: 400px;
  }
  .WhatWeDo-content-1 div {
    margin-top: 50px !important;
  }
  .WhatWeDo-content-2 div {
    margin-top: 150px !important;
    margin-left: 100px !important;
  }
  .WhatWeDo-content-1 div p,
  .WhatWeDo-content-2 div p {
    font-size: 20px !important;
    max-width: 90%;
    line-height: 25px !important;
  }
}

@media (max-width: 999px) {
  .whatwedo-scroll-to {
    display: none;
  }
}

@media (max-width: 700px) {
  .bg-boy {
    background-size: 250px 100vw;
    background-repeat: no-repeat;
    min-height: 250px;
    padding: 0 0;
  }
  .mobile-version {
    display: block !important;
  }
}

@media (max-width: 500px) {
  .bg-boy-image {
    height: 200px;
  }
}

@media (max-width: 900px) {
  .bg-boy {
    display: none;
  }
  .mobile-version {
    display: block !important;
  }
}

.WhatWeDo-content-1 div {
  margin-top: 100px;
  width: 550px;
  margin-left: 200px;
}

.WhatWeDo-content-1 div p,
.WhatWeDo-content-2 div p {
  font-size: 27px;
  line-height: 45px;
}

.WhatWeDo-content-2 div {
  margin-top: 200px;
  width: 500px;
  margin-left: 300px;
}

@media (max-width: 1600px) {
  .WhatWeDo-content-1 div {
    margin-left: 100px;
  }
  .WhatWeDo-content-2 div {
    margin-left: 200px;
  }
}
