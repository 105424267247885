.signUp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.input {
  height: 100px;
  border: 2px solid;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  background-color: transparent;
  font-size: 30px;
  margin-left: 10px;
}

@media (max-width: 1550px) and (min-width: 1000px) {
  .odd-one {
    margin-left: 300px;
  }
  .signup-scroll {
    left: 50px !important;
  }
}
.visual-correction {
  font-size: 15px;
  color: red;
  font-weight: lighter;
  margin: 0;
  margin-left: 10px !important;
  text-align: left;
}
.warning-2 {
  font-size: x-small !important;
  max-width: 205px;
  margin-left: 50px;
  text-align: left;
  border: 1px solid #f7e771;
  padding: 2px;
}

@media (min-width: 700px) {
  .warning-2 {
    display: none;
  }
}

@media (max-width: 600px) {
  .input {
    width: 90vw !important;
  }
}

.input::placeholder {
  font-size: 30px;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  margin-left: 10px;
}

.TextBox {
  width: fit-content;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.signup-scroll {
  position: absolute;
  left: 100px;
  bottom: 150px;
}

.textbox-label {
  font-size: 30px !important;
  text-align: left;
  margin: 2px 5px;
}
.mani-p {
  font-size: 20px !important;
}
@media (max-width: 1000px) {
  .signup-scroll {
    display: none;
  }
}
@media (max-width: 500px) {
  .mani-p {
    font-size: 15px !important;
    padding: 2px 10px;
  }
  .contact-us-number {
    margin-left: calc(-100vw + 150px);
  }
}

@media (max-width: 600px) {
  .textbox-label {
    font-size: 20px !important;
  }
  .signup-button {
    bottom: 10px !important;
    position: relative !important;
    right: 0 !important;
  }
}

.select-container {
  width: 80vw;
  margin: auto;
}

.signup-button {
  position: absolute;
  bottom: -150px;
  right: calc(30vw - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
