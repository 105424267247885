.round-2 {
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.round-2 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 20px;
}

.round-2 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}

.round-2 input[type="radio"] {
  visibility: hidden;
}

.round-2 input[type="radio"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round-2 input[type="radio"]:checked + label:after {
  opacity: 1;
}

.checkbox-text {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: rgba(51, 50, 50, 0.83);
  line-height: 30px;
}
