.second {
  min-height: 600px;
  max-height: 1000vh;
  overflow: hidden;
}
.img-1 {
  width: 380px;
  height: 400px;
  position: absolute;
  top: 40px;
  left: 60px;
  background-image: url("../../assets/images/Chidren/1.jpg");
  background-position: center center;
  background-size: 100% 100%;
  z-index: 0;
}

.img-1-shade {
  width: 380px;
  height: 400px;
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 60px;
  background-color: rgba(255, 132, 0, 0.591);
  background-position: center center;
  opacity: 0.5;
  background-size: 100% 100%;
}

.secont-adjust {
  margin-left: -20px !important;
}
@media (max-width: 1000px) {
  .second-scroll {
    display: none;
  }
}
.absolute-container {
  height: 600px !important;
}

@media (max-width: 900px) and (min-width: 500px) {
  .absolute-container {
    max-height: 300px;
  }
  .secont-adjust {
    max-width: 90%;
  }
}

@media (max-width: 992px) and (min-width: 900px) {
  .secont-adjust {
    max-width: 90%;
  }
}

.img-2 {
  width: 250px;
  height: 450px;
  position: absolute;
  top: 90px;
  left: 530px;
  background-image: url("../../assets/images/Chidren/2.jpg");
  background-position: center center;
  background-size: 100% 100%;
  z-index: 0;
}

.img-2-shade {
  width: 250px;
  height: 450px;
  position: absolute;
  z-index: 1;
  top: 90px;
  left: 530px;
  background-color: rgba(255, 132, 0, 0.591);
  background-position: center center;
  opacity: 0.5;
  background-size: 100% 100%;
}

@media (max-width: 900px) and (min-width: 701px) {
  .img-1,
  .img-1-shade {
    width: 250px;
    height: 200px;
    left: 100px;
  }
  .img-2,
  .img-2-shade {
    width: 150px;
    height: 300px;
    left: 360px;
  }
}

@media (max-width: 1550px) and (min-width: 1000px) {
  .img-1,
  .img-1-shade {
    width: 300px;
    height: 350px;
  }
  .img-2,
  .img-2-shade {
    width: 200px;
    height: 400px;
    left: 400px;
  }
  /* .secont-adjust {
    margin: auto !important;
  } */
}

@media (max-width: 700px) {
  .absolute-container {
    height: 300px !important;
  }
  .img-1,
  .img-1-shade {
    width: 160px;
    height: 200px;
    left: 40px;
  }
  .img-2,
  .img-2-shade {
    top: 100px;
    left: 220px;
    width: 130px;
    height: 200px;
  }
  .secont-adjust {
    margin-left: 0 !important;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .img-1,
  .img-1-shade {
    width: 240px;
    height: 160px;
    left: 30px;
  }
  .img-2,
  .img-2-shade {
    top: 20px;
    left: 280px;
    width: 150px;
    height: 210px;
  }
}

@media (max-width: 991px) and (min-width: 701px) {
  .absolute-container {
    height: 800px !important;
  }
  .second {
    max-height: 1500px;
  }
}

.secont-adjust {
  margin-left: 100px;
}
