.close-icon {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor: pointer;
}
.close-icon:hover {
  opacity: 1;
}
.close-icon:before,
.close-icon:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 3px;
  border-radius: 3px;
  background-color: rgb(0, 0, 0);
}
.close-icon:before {
  transform: rotate(45deg);
}
.close-icon:after {
  transform: rotate(-45deg);
}
